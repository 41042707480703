*,
*:before,
*:after {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

.demo {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    min-height: 90%;
    font-family: "Open Sans", sans-serif;
}

.demo__description {
    font-size: 20px;
    padding-bottom: 20px;
}

.demo__phone {
    position: relative;
    width: 305.25px;
    height: 613px;
    min-height: 613px;
    padding-top: 1px;
    background-color: #59399D;
    border-radius: 40px;
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.5);
}

.demo__notch {
    position: absolute;
    top: 12px;
    left: 74.25px;
    height: 22.5px;
    width: 156.75px;
    background-color: #120d30;
    border-radius: 0 0 15px 15px;
}

.demo__screen {
    position: relative;
    height: 589px;
    min-height: 589px;
    width: 281.25px;
    padding-top: 25px;
    /* background: linear-gradient(#4a3972, #5e4a9d); */
    background-image: url(https://user-images.githubusercontent.com/15075759/28719144-86dc0f70-73b1-11e7-911d-60d70fcded21.png);
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 30px;
    cursor: -webkit-grab;
    cursor: grab;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    perspective: 700px;
}

.demo__screen-wrapper {
    position: relative;
    height: 609px;
    width: 281.25px;
    margin-top: 11px;
    margin-left: 12px;
    overflow: hidden;
    border-radius: 30px;
}

.demo__indicator {
    position: absolute;
    top: 2.5px;
    font-size: 13.5px;
    color: white;
}

.demo__indicator--time {
    left: 18.75px;
}

.demo__indicator--signal {
    right: 45px;
}

.demo__indicator--battery {
    font-size: 14.25px;
    right: 16.5px;
}

.demo__controls {
    position: relative;
    display: flex;
    justify-content: center;
    padding: 6.25px 0;
    font-size: 18.75px;
    color: white;
}

.demo__arrow {
    position: absolute;
    left: 12.5px;
}

.demo__card {
    position: relative;
    margin: 9px;
    height: 142.5px;
    width: calc(100% - 18px);
    font-size: 17.5px;
    border-radius: 5px;
    transform-style: preserve-3d;
}

.demo__card--notched {
    position: absolute;
    top: 0;
    transform: rotateX(180deg) scale(0.6) translateY(175px);
}

.demo__card--processing {
    position: absolute;
    top: 0;
    transform: translateY(62.5px);
}

.demo__card-frontside {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    padding-top: 9px;
    padding-bottom: 4.5px;
    background-color: white;
    border-radius: 5px;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}

.demo__card-backside {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    color: white;
    background: linear-gradient(#120d30 50%, #211858);
    border-radius: 25px;
    transform: rotateX(180deg);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}

.demo__card-release {
    padding: 5px;
    opacity: 0;
    transition: opacity 0.2s ease;
}

.demo__card-type {
    padding-left: 18px;
    font-size: 12.5px;
    color: #aaa;
}

.demo__card-body {
    display: flex;
    align-items: center;
    padding-left: 18px;
    padding-right: 18px;
    padding-top: 9px;
    padding-bottom: 9px;
}

.demo__card-picture {
    width: 50px;
    height: 50px;
    flex-shrink: 0;
    border-radius: 50%;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

.demo__card-picture--starbucks {
    background-image: url(https://kiyutink.github.io/logos/starbucks.svg);
}

.demo__card-picture--citi {
    background-image: url(https://kiyutink.github.io/logos/citi.png);
}

.demo__card-picture--paypal {
    background-image: url(https://kiyutink.github.io/logos/paypal.svg);
}

.demo__card-picture--apple {
    background-image: url(https://kiyutink.github.io/logos/apple.png);
}

.demo__card-info {
    width: 70%;
    margin-left: 9px;
    margin-right: 9px;
}

.demo__card-caption {
    width: 100%;
    font-weight: 700;
}

.demo__card-description {
    min-height: 37.5px;
    font-size: 13.75px;
}

.demo__card-money {
    font-size: 17.5px;
    font-weight: 700;
}

.demo__card-bottom {
    display: flex;
    justify-content: space-between;
    border-top: 1px solid #ddd;
}

.demo__card-button {
    padding-top: 4.5px;
    padding-left: 18px;
    color: #6b6281;
    cursor: pointer;
}

.demo__card-time {
    padding-top: 9px;
    padding-right: 18px;
    font-size: 12.5px;
    color: #ddd;
}

.animation {
    transition: transform 0.5s cubic-bezier(0.55, 0.26, 0.12, 1.19);
}

.short-animation {
    transition: transform 0.2s ease;
}


.button-action {
    border-top: 1px solid #ddd;
    width: 100%;
    height: 30px;
    background: "blue";
    display: flex;
    justify-content: center;
    align-items: center;
}